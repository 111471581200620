import React from 'react';
import { FelaComponent, connect as connectFela } from 'react-fela';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import logo from '../../images/ackee.svg';

import Text from '../Text';

import * as componentStyles from './Footer.style';
import { Col, Row } from 'antd';
import Heading2 from '../Heading2';
import { compose } from 'redux';
import Link from '../Link';
import Address from '../Address/Address';
import SocialIcons from '../Home/Footer/Social/SocialIcons';
import { LinkType } from '../Link/Link.style';

const links = [
    { to: '/features', labelId: 'footer.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'footer.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'footer.menu.conference', type: LinkType.GATSBY },
    { to: 'footer.menu.aboutUrl', trans: true, labelId: 'footer.menu.about', type: LinkType.NATIVE },
    { to: 'footer.menu.careerUrl', trans: true, labelId: 'footer.menu.career', type: LinkType.NATIVE },
    { to: '/privacyPolicy', labelId: 'footer.menu.gdpr', type: LinkType.GATSBY },
];

const berlinAddressTitle = 'footer.berlin.title';
const berlinAddress = ['footer.berlin.company', 'footer.berlin.street', 'footer.berlin.city', 'footer.berlin.country'];

const pragueAddressTitle = 'footer.prague.title';
const pragueAddress = ['footer.prague.company', 'footer.prague.street', 'footer.prague.city', 'footer.prague.country'];

const Footer = ({ styles, intl }) => (
    <footer id="contacts" className={styles.wrapper}>
        <FelaComponent style={componentStyles.inner}>
            <FelaComponent style={componentStyles.top}>
                <Row>
                    <Col xs={24} sm={12} lg={6}>
                        <nav className={styles.nav}>
                            {links.map(({ to, trans, type, labelId, forceLocale = false }, i) => (
                                <div key={i}>
                                    <Link
                                        type={type}
                                        target="_blank"
                                        to={trans ? intl.formatMessage({ id: to }) : to}
                                        forceLocale={forceLocale}
                                    >
                                        <FormattedMessage id={labelId} />
                                    </Link>
                                </div>
                            ))}
                        </nav>
                    </Col>
                    <Col xs={24} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 2 }}>
                        <Address data={pragueAddress} titleId={pragueAddressTitle} />
                    </Col>
                    <Col xs={24} sm={12} lg={5}>
                        <Address data={berlinAddress} titleId={berlinAddressTitle} />
                    </Col>
                    <Col
                        className={styles.socialiconsWrapper}
                        xs={{ span: 22, offset: 0 }}
                        lg={{ span: 6, offset: 1 }}
                        xl={{ span: 5, offset: 2 }}
                    >
                        <Heading2 element="h2" color="white">
                            {intl.formatMessage({ id: 'footer.follow.title' })}
                        </Heading2>
                        <SocialIcons />
                    </Col>
                </Row>
            </FelaComponent>
            <FelaComponent style={componentStyles.bottom}>
                <Row>
                    <Col xs={24} lg={16}>
                        <Text size="small" color="greyLighter">
                            <FormattedHTMLMessage tagName="span" id="event.footer" />
                        </Text>
                    </Col>
                    <Col xs={24} lg={8}>
                        {/* eslint-disable */}
                        {/* disable because we want to track visitors in google analytics */}
                        <a
                            href={intl.formatMessage({ id: 'footer.ackee.url' })}
                            target="_blank"
                            className={styles.ackeeLogo}
                        >
                            <img src={logo} alt="Ackee logo" />
                        </a>
                        {/* eslint-enable */}
                    </Col>
                </Row>
            </FelaComponent>
        </FelaComponent>
    </footer>
);

export default compose(connectFela(componentStyles), injectIntl)(Footer);
