const activeStyle = {
    paddingBottom: 1,
    borderBottom: 'none',
};

export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.greyDarkest,
    paddingBottom: '0.25rem',
    '& .Heading2': {
        letterSpacing: 0,
    },
});

export const top = ({ theme }) => ({
    marginBottom: '6rem',
    max992px: {
        '& > div > div': {
            marginBottom: '4rem',
        },
    },
});

export const inner = ({ theme }) => ({
    paddingTop: '6rem',
    paddingBottom: '1rem',
    margin: '0 auto',
    maxWidth: '90rem',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    min768px: {
        paddingLeft: '3.25rem',
        paddingRight: '3.25rem',
    },
    min1200px: {
        paddingLeft: '5.25rem',
        paddingRight: '5.25rem',
    },
    textAlign: 'left',
    '& .Text': {
        fontSize: '1.1rem',
        lineHeight: 1.5,
        color: theme.colors.greyFooter,
    },
    '& .Text a': {
        paddingBottom: 0,
        color: theme.colors.green,
        borderBottom: `1px solid ${theme.colors.green}`,
        textDecoration: 'none',
        ':hover': activeStyle,
        ':focus': activeStyle,
        ':active': activeStyle,
    },
    '& p': {
        padding: 0,
    },
    '& .Heading2': {
        padding: 0,
        fontSize: '1.75rem',
        margin: 0,
    },
    '& strong': {
        fontWeight: 700,
    },
});

export const socialiconsWrapper = ({ theme }) => ({
    min1200px: {
        textAlign: 'right',
    },
});

export const ackeeLogo = ({ theme }) => ({
    float: 'right',
    max768px: {
        float: 'left',
        marginTop: '1rem',
    },
});

export const bottom = ({ theme }) => ({
    borderTop: `1px solid ${theme.colors.greyFooter}`,
    fontWeight: 500,
    paddingTop: '1.5rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    '& .Text': {
        fontSize: '1rem',
        opacity: 0.5,
        color: theme.colors.white,
    },
});

export const nav = ({ theme }) => ({
    marginTop: '1rem',
    '& a': {
        color: theme.colors.white,
        border: 'none',
        fontWeight: 500,
        lineHeight: '2.25rem',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
});
