import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { combineRules } from 'fela';

import style from './Address.style';
import textStyle, { TextSize } from '../Text/Text.style';
import Heading2 from '../Heading2';
import { injectIntl } from 'react-intl';

const Address = ({ titleId, data, intl }) => (
    <address>
        <dl>
            <Heading2 element="dt" color="white">
                {intl.formatMessage({ id: titleId })}
            </Heading2>
            {data.map(id => (
                <FelaComponent as="dd" size={TextSize.SMALL} style={combineRules(textStyle, style)} key={id}>
                    {intl.formatMessage({ id })}
                </FelaComponent>
            ))}
        </dl>
    </address>
);

Address.propTypes = {
    titleId: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(Address);
