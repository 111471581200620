import React from 'react';
import { connect as connectFela } from 'react-fela';
import PropTypes from 'prop-types';

import * as componentStyles from './SocialIcon.style';

import instagram from '../../../../images/social/instagram.svg';
import linkedin from '../../../../images/social/linkedin.svg';
import facebook from '../../../../images/social/fb.svg';

const items = [
    {
        alt: 'Instagram icon',
        src: instagram,
        url: 'https://www.instagram.com/ackeecz',
    },
    {
        alt: 'LinkedIn icon',
        src: linkedin,
        url: 'https://www.linkedin.com/company/ackee/',
    },
    {
        alt: 'Facebook icon',
        src: facebook,
        url: 'https://www.facebook.com/AckeeCZ',
    },
];

const SocialIcons = ({ styles }) =>
    items.map(item => (
        // disable because we want to track visitors in google analytics
        // eslint-disable-next-line
        <a target="_blank" href={item.url} rel={'noopener nofollow'} className={styles.link} key={item.url}>
            <img src={item.src} alt={item.alt} />
        </a>
    ));

SocialIcons.propTypes = {
    styles: PropTypes.shape({
        link: PropTypes.string,
    }),
};

export default connectFela(componentStyles)(SocialIcons);
