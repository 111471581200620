import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { combineRules } from 'fela';

import style from './Heading2.style';

const Heading2 = ({ color, element, overrideStyles, ...props }) => (
    <FelaComponent color={color} style={combineRules(style, overrideStyles)}>
        {({ className }) =>
            React.createElement(
                element,
                { ...props, className: `Heading2 ${props.className} ${className}` },
                props.children,
            )
        }
    </FelaComponent>
);

Heading2.propSizes = {
    className: PropTypes.string,
    color: PropTypes.string,
    element: PropTypes.string,
    overrideStyles: PropTypes.func,
};

Heading2.defaultProps = {
    className: '',
    color: 'black',
    element: 'h2',
    overrideStyles: () => {},
};

export default Heading2;
