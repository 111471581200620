export default ({ theme, color = 'black' }) => ({
    padding: '0 1rem',

    fontSize: '2.25rem', // 36px
    fontWeight: '700',
    lineHeight: '3rem', // 56px
    letterSpacing: -1,

    color: theme.colors[color] || color,

    max992px: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});
